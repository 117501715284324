import React from 'react';
import './Experience.css';
import TextDisplay from '../components/TextDisplay';

function Experience() {
    return (
        <div className="page-container">
          <div className="experience-container" id="experience">
            <div className="etext-container">
              <h4 className="experience-text-first">
                <span className="highlighted-word">2.</span> Where I've Worked
              </h4>
            <div className="horizontal-line" />
            <TextDisplay />
          </div>
        </div>
      </div>
    );
  }
  
  export default Experience;