import React from 'react';
import './Home.css'; // Import the CSS file for styling

function Home() {
  return (
    <div className="home-container">
      <div class="text-container">
        <p className="home-text-name">Hi, my name is</p>
        <h2 className="home-text-first">Jake Grigorian.</h2>
        <h3 className="home-text-second">I write code.</h3>
        <p className="home-text-main">I'm a software engineer with experience in research software, fullstack, and quantitative development. Currently, I'm pursuing my <span class="highlighted-word">B.S. in Computer Science</span> at <span class="highlighted-word">USC</span>.</p>
          <a href="#about" class="button-link">
            <button class="button2">Tell me more!</button>
          </a>
      </div>
    </div>
  );
}

export default Home;