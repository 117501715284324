import React from 'react';
import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import Contact from './pages/Contact';
import About from './pages/About';
import Experience from './pages/Experience';
import Projects from './pages/Projects';

function App() {
  return (
    <div className="app-container">
      <Header />
      <Home />
      <About />
      <Experience />
      <Contact />
      <Projects />
      <Footer />
    </div>
  );
}

export default App;
