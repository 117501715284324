import React from 'react';
import './Footer.css';

function Footer() {
    return (
        <footer>
            <div>
                <ul className="social-list">
                    <li>
                        <a href="https://github.com/jgrigorian23" target="_blank" rel="noopener noreferrer">
                            <img src="/github.svg" alt="Github" className="social-image" />
                        </a>
                    </li>
                    <li>
                        <a href="https://www.linkedin.com/in/jake-grigorian-1240b7282/" target="_blank" rel="noopener noreferrer">
                            <img src="/linkedin.svg" alt="LinkedIn" className="social-image" />
                        </a>
                    </li>
                    <li>
                        <a href="https://www.quantconnect.com/u/jake-grigorian" target="_blank" rel="noopener noreferrer">
                            <img src="/quantgrey.svg" alt="Quant" className="social-image" />
                        </a>
                    </li>
                </ul>
                <div class="social-line"></div>
            </div>
            <div>
                <div class="vertical-text-container">
                    <div class="vertical-text">
                        <a href="mailto:jakepaulgrigorian@gmail.com">jakepaulgrigorian@gmail.com</a>
                    </div>
                    <div class="vertical-line-container"></div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;