import React from 'react';
import './Projects.css';

/* Projects include: 
            
  SF Lit Mag Website (made in go)
  Caltech Software (Link github here)
  This site (Your personal porftolio site made in react)
            
*/

function Projects() {
  return (
    <div className="page-container">
      <div className="projects-container" id="projects">
        <div className="ptext-container">
          <h4 className="project-text-first">
            <span className="highlighted-word">3.</span> Some Of My Projects
          </h4>
          <div className="horizontal-line" />
          <div class="button-list">
            <ul>
            <li>
              <a href="https://github.com/jgrigorian23/sneaker-quant-price" target="_blank" className="button-link">
                <button>
                  <img src="folder.svg" alt="Image 1" />
                  <span class="button-text">
                    <h4>Quantitative Shoe Price Predictor</h4>
                    <p>A linear regression model with 5 indicators that uses quantitative strategies to predict sneaker prices with 97.6% accuracy</p>
                    <p><span className="button-text2">Python - Jupyter Notebook</span></p>
                  </span>
                </button>
                </a>
              </li>
              <li>
                <a href="https://github.com/jgrigorian23/Brown-Dwarf-Simulation-Code" target="_blank" className="button-link">
                <button>
                  <img src="folder.svg" alt="Image 1" />
                  <span class="button-text">
                    <h4>Caltech Brown Dwarf Simulation Code</h4>
                    <p>Research software that simulates non-planetary brown dwarfs by returning references to age, mass, and temperature</p>
                    <p><span className="button-text2">Python - Github</span></p>
                  </span>
                </button>
                </a>
              </li>
              <li>
              <a href="https://the-shield.herokuapp.com/" target="_blank" className="button-link">
                <button>
                  <img src="folder.svg" alt="Image 1" />
                  <span class="button-text">
                    <h4>The Shield Literary Magazine</h4>
                    <p>Official website for The Shield Literary Magazine that includes Poetry, Nonfiction, Fiction, Art, Photography, Film and Music</p>
                    <p><span className="button-text2">Go - HTML/CSS - Heroku</span></p>
                  </span>
                </button>
                </a>
              </li>
            </ul>
          </div>
          <span className="bottom-text">And more on the way!</span>
            <a href="#contact" class="button-link">
              <button class="button4">I'm interested!</button>
            </a>
        </div>
      </div>
    </div>
  );
}

export default Projects;