import React from 'react';
import './Contact.css'

function Contact() {
    return (
      <div className="contact-container" id="contact">
        <div className="ctext-container">
          <p className="contact-text-name">4. Contact</p>
          <h2 className="contact-text-first">Get In Touch</h2>
          <p className="contact-text-main">Reach out if you'd like to see my full resume, demos for my startup, or anything in between. I'm always looking for new opportunities for internships, projects, and more - whether you have an inquiry or just want to connect, I'm quick to respond!</p>
          <div className="button3-container">
            <a href="mailto:jpgrigor@usc.edu" className="button-link">
              <button className="button3">Let's Talk!</button>
            </a>
          </div>
          <p className="contact-text-bottom">Website Built by <span class="highlighted-word">Jake Grigorian</span> <br /><i>Have a beautiful day</i></p>
        </div>
      </div>
    );
}

export default Contact;
