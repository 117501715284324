import React from 'react';
import './About.css';
import me2 from '../images/me2.jpg';
import megreen2 from '../images/megreen2.png';

function About() {
  return (
    <div className="page-container">
    <div className="about-container" id="about">
      <div className="atext-container">
        <h4 className="about-text-first">
          <span className="highlighted-word">1.</span> About Me
        </h4>
        <div className="horizontal-line" />
        <p className="about-text-main">
          My interest in coding started at <span className="highlighted-word">age 7</span>, when I did my first Hour of Code and made a music player on Scratch - it was no Spotify, but I loved making it!
        </p>

        <p className="about-text-main">
          Today, I've <span className="highlighted-word">co-founded</span> my own <span className="highlighted-word">startup</span>,
          have <span className="highlighted-word"> published </span> multiple peer-reviewed <span className="highlighted-word">computational astrophysics papers</span> at <span className="highlighted-word"> Caltech</span>, <span className="highlighted-word"> interned </span> at the <span className="highlighted-word">coding academy</span> where I was once a student, and built my high school's first ever <span className="highlighted-word">literary magazine website</span> from the ground up.
        </p>

        <p className="about-text-main">
          Throughout the years, I've been working with <span className="highlighted-word">languages</span> and <span className="highlighted-word">frameworks</span> like:
        </p>
        <div className="lists-container">
          <ul className="list">
            <li>Python</li>
            <li>TypeScript</li>
            <li>JavaScript</li>
          </ul>
          <ul className="list">
            <li>C++</li>
            <li>Java</li>
            <li>SQL</li>
          </ul>
          <ul className="list">
            <li>React.js</li>
            <li>PostgreSQL</li>
            <li>Next.js</li>
          </ul>
        </div>
      </div>
      <div className="image-container">
      <button className="button-56">
        <span className="button-text"></span>
        <div className="image-wrapper">
          <img src={megreen2} alt="Green Me" className="image-normal" />
          <img src={me2} alt="Me" className="image-hovered" />   
        </div>
      </button>
      </div>
    </div>
    </div>
  );
}

export default About;
