import React from 'react';
import './Navigation.css';
import logo from '../logo2.png';

function Navigation() {
    const handleLinkedClick = () => {
        window.open('https://www.linkedin.com/in/jakegrigorian/', '_blank');
    };
    const handleGitClick = () => {
        window.open('https://github.com/jgrigorian23', '_blank');
    };
    const handleResearchClick = () => {
        window.open('https://arxiv.org/search/astro-ph?searchtype=author&query=Grigorian,+J', '_blank');
    };
    return (
        <nav className="navigation">
            <a href="/" style={{ textDecoration: 'none' }}>
                <button style={{ marginLeft: '10px', border: 'none', background: 'none', padding: 0, cursor: 'pointer' }}>
                    <img src={logo} alt="Logo" style={{ background: 'transparent', border: 'none' }} />
                </button>
            </a>
            <ol class="horizontal-list">
                 <li><a href="#about">About</a></li>
                 <li><a href="#experience">Experience</a></li>
                 <li><a href="#projects">Projects</a></li>
                 <li><a href="#contact">Contact</a></li>
                 <div>
                    <button onClick={handleResearchClick} className="buttonicus">
                        Research Papers
                    </button>
                 </div>
                 <div>
                    <button onClick={handleLinkedClick} className="buttonicus">
                        LinkedIn
                    </button>
                 </div>
                 <div>
                    <button onClick={handleGitClick} className="buttonicus">
                        Github
                    </button>
                 </div>
            </ol>
        </nav>
    );
}

export default Navigation;
